
  /*!
  * ol-contextmenu - v3.3.2
  * https://github.com/jonataswalker/ol-contextmenu
  * Built: Thu Jun 27 2019 07:33:24 GMT-0300 (Brasilia Standard Time)
  */
.ol-ctx-menu-container {
  position: absolute;
  padding: 8px;
  background: #fff;
  color: #222;
  font-size: 13px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px;
  box-sizing: border-box;
}
.ol-ctx-menu-container div, .ol-ctx-menu-container span, .ol-ctx-menu-container a, .ol-ctx-menu-container img, .ol-ctx-menu-container ul, .ol-ctx-menu-container li {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.ol-ctx-menu-container a img {
  border: none;
}
.ol-ctx-menu-container *,
.ol-ctx-menu-container *::before,
.ol-ctx-menu-container *::after {
  box-sizing: inherit;
}
.ol-ctx-menu-container.ol-ctx-menu-hidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 300ms, opacity 300ms;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.ol-ctx-menu-container ul {
  list-style: none;
}
.ol-ctx-menu-container li {
  position: relative;
  line-height: 20px;
  padding: 2px 5px;
}
.ol-ctx-menu-container li:not(.ol-ctx-menu-separator):hover {
  cursor: pointer;
  background-color: #333;
  color: #eee;
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu .ol-ctx-menu-container {
  border: 1px solid #eee;
  padding: 8px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 300ms, opacity 300ms;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu:hover .ol-ctx-menu-container {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu::after {
  position: absolute;
  top: 7px;
  right: 10px;
  content: "";
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  border-right: 0.3em solid #222;
  border-top: 0.3em solid #222;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu:hover::after {
  border-color: #eee;
}
.ol-ctx-menu-container li.ol-ctx-menu-separator {
  padding: 0;
}
.ol-ctx-menu-container li.ol-ctx-menu-separator hr {
  border: 0;
  height: 1px;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0)), color-stop(rgba(0, 0, 0, 0.75)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.ol-ctx-menu-icon {
  text-indent: 20px;
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: left center;
}

.ol-ctx-menu-zoom-in {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABaUlEQVQ4T72U7VHCQBCGn90GtAMuNGCswFiBWIFQgWMFxg6wArECsQKhArEBiB1Qwa1zgQn5IAYcxv13k71n3919L8KJQ07M47+BzgG9TRfZ/JBuWhS6BJFHRJICYrZGZIz3z5Ct2+B7gG6I6kt+wewdkQVwjtkAkR5mC8yu26A1oItR/cTsOweQBdgutD8G7jGm2PJ2n8oqUKIpIjd4HxTM8gvaT/F+AlmWnyWaIXKF95eNguFzTYFhNsdWu9kFgFlaFMANUH3D8wDLoLgSTSD2il8NCe2ZXQBxWDGwxmyUzzOMBZ7wy7Qb2K0wQfXjMOBuhlFpZtNty5sFaTQBuTusZdymeqs1SpYKcO9HkE3KbTd9WFijMHJQ5hBNEAYNq5Qd0dhyke0GiE4QzjqfW23mHT8Hl4DG4Lce3FPE7AtbBSdsbNqpoJLgYkRnNeUV+xwJDHTnUEkxHGbhBXUs5TjJjew/KPy94g+NRaIVRYmMXwAAAABJRU5ErkJggg==");
}

.ol-ctx-menu-container li:hover.ol-ctx-menu-zoom-in {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABc0lEQVQ4T71U21ECQRDsJgGdvQDECMQIxAjECMQILCPwzAAjECIQI0AiEDPQAPaWCBhrcKHuCUcV5f7dY3v6tUscefHIePhfwBBCF8CZqRCReRs1tQxDCH1VfQLQz4EsSY4AvIjIsgm8AhhCGKrqa9zwrqoLAKckB5HtguR1E2gBMITQU9VPAD8GICIGtl3e+xHJBwBT59xtHcsCYJZlUwA3kcGHbfDep51OZywi3/acZZm9vyJ5WR5o38uACmDunNt6ZwAkUxFZDwghDFT1jeSjiJinhVUBVNVJkiTDKO8CQA+AsbNQ7s1Ps0VVn5MkSfcCtmBoDZi1Bdx4eJ7zbBolrwPy3o9J3rWSHPs3A1BbjVKlYBaIyDgvu9LDXDU2RTZmXVW1oKyLxRD+OrkOrJLy5mVM0iaftDhuhVbsvBzMglzKUNW6IV/OOWtCM8MmVvEkmbwt83LaB19fdgOtVquUZJeknaDdobTwbOcvBzPcN/AXH1DFFWP7u9oAAAAASUVORK5CYII=");
}

.ol-ctx-menu-zoom-out {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABU0lEQVQ4T72U7VECMRRFz3sNaAdkacC1AtcKxApcKnCsQOwAK3CtQKxAqEBsANYOqCDPyTIC+8WCw5jfybn33dxEOPGSE/P4b6BzQG89RT47ZJoWhy5B5BGRZAMxWyEyxvtnyFdt8AagS1F9KQ6YvSMyB84xGyDSw2yO2XUbtAJ0MaqfmH0XAPIA2y7tj4F7jAm2uG1yWQZKNEHkBu+Dg2njWBJNEbnC+8uaIFRuWfuG2QxbbrOrUd0A1Tc8D7AIjkur7DAAsVf8MiWMZ3ZR2m02LPIMscATfjHqBnY7TFD9OAy4zTCCPG/MUKMM5O6wkXFr9dZq7FQqqHk/hDzbFa73cFONTZFDdRyiCcKg5rrSiLaXkiI6RjjrfG6VzDs+B5eAxuDXeYpmNRGzL2wZ/wof+du4GNFpBVqqz5HA4MM5VEYYDrOs+1I6Q9u/4Q8O9wN/AGgWjBVqQjjgAAAAAElFTkSuQmCC");
}

.ol-ctx-menu-container li:hover.ol-ctx-menu-zoom-out {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABYklEQVQ4T72U4VHCQBCF36tA91KAWIFYgViBWIFYgWMFYgdYgVCBWAFSgdiBFpAsFWSdxcDkQoBkhnF/ZjbfvX377ogjF4/Mw/8CVbUD4MynEJF5k2lqFapqz8yeAPRKkCXJEYAXEVnugm8BVXVgZq/FD+9mtgBwSrJfqF2QvN4FjYCq2jWzTwA/DhARh20qTdMRyQcA0xDCbZ3KCJhl2RTATaHgo+6HLMv8+xXJy+qB3l8FGoB5CKHsXcRV1b6ZvZF8FBH3NKotoJlNkiQZFONdlLtJ3rufbouZPSdJMjwIbKDQEzBrClx7eC4i33Uepmk6JnnXaOQifzMAtdGoRApugYiMI1uqKkrRWAfZo9MxM1+UZzFewl8mN4nYdVM83L7BkwbXLUrF3sfBLQDQBbDy08x8vOohXyEE71lVq9emuEk+3gZa3XYroCvwFyjP8yHJDsnxwaU08GxvS2uFhw78BbzWrxXgMbsHAAAAAElFTkSuQmCC");
}